.add-event-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.event-input,
.event-textarea,
.event-dropdown {
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 178px;
  overflow: hidden;
  }
  
  .responsive-iframe-container iframe,
  .vresponsive-iframe-container object,
  .vresponsive-iframe-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 95%;
  height: 60%;
  }

  h4 a {
    display: inline-block;
}
